import React from "react"
import styles from "./customtitle.module.css"



class CustomTitle extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={this.props.style}>
                <h1 className={styles.title} style={this.props.titleStyle}>{this.props.title}</h1>
                <div className={styles.bar} style={{backgroundColor: this.props.barColor}}/>
            </div>
        )
    }
}
export default CustomTitle