import React from "react"
import Layout from "../components/layout"
import Images from "../images/images.js"
import SEO from "../components/seo"
import styles from "./themes.module.css"
import faq from "../../res/faq.json";
import CustomTitle from "../components/CustomTitle";
import StoryCell from "../components/StoryCell";

const {
  appStoreImage,
  plusFAQ,
  minusFAQ,
  playStoreImage,
  landingHeroDesktop,
  storyIsland,
  storyOperation,
  storyPirate,
  storyPirates02,
  storyCut,
  storyUnderground,
  storyTrapApp,
  storyBuried,
  storyCabinFever,
  landingHeroMobile} = Images;

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      sliderPage: 1,
      size: null,
      visibleAnswers: [],
      message: '',
      email: '',
      subject: '',
      name: '',
      submissionClicked: false,
      response: 0,

      storyDetails: {
        title: "Temp",
        desc: "Temp",
        chapters: 0
      },
      showMobileStory: false
    };

    this.answerVisibility = this.answerVisibility.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);

    this.storyCellMobile = this.storyCellMobile.bind(this);
    this.storyCellMobileHide = this.storyCellMobileHide.bind(this);
  }

  componentDidMount() {
    this.setState({
        size: window.innerWidth
    });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.addEventListener('resize', this.handleResize);
  }

  handleResize() {
      this.setState({
          size: window.innerWidth
      })
  }

  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  handleChangeMessage = (event) => {
    this.setState({
      message: event.target.value
    })
  }

  handleChangeSubject = (event) => {
    this.setState({
      subject: event.target.value
    })
  }

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  sendMessage() {
    const { email, subject, message, name } = this.state;  

    this.setState({
      submissionClicked: true
    })

    if (email && subject && message && name) {
      let query = `mutation {
        contactUs(subject: "${subject}", message: "${message}", senderEmail: "${email}", senderName: "${name}") {
          newSubmission {
            id
          }
        }
      }  `      

      fetch('https://api.pathsapp.co/graphql', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({query: query}),
      })

      .then(res => {
        const resp = res.status;
        this.setState({
          response: resp
        })
      }
      )
      // .then(res => res.json())
      // .then(res => console.log('Success:', JSON.stringify(res.data)))
      .catch(error => console.error('Error', error))

    } else {

    }
  }

  answerVisibility(index) {   
    if (this.state.visibleAnswers.indexOf(index) <= -1) {
      this.setState({
          visibleAnswers: [...this.state.visibleAnswers, index]
      })
    }
    else {
        let position = this.state.visibleAnswers.indexOf(index);
        this.setState({
            visibleAnswers: this.state.visibleAnswers.filter((_, i) => i !== position)
        });
    }
  }

  storyCellMobile(details) {
    if (this.state.size <= 1024 && !this.state.showMobileStory) {
      this.setState({storyDetails: details, showMobileStory: true});
    } else {
      this.storyCellMobileHide();
    }
  }

  storyCellMobileHide() {
    this.setState({showMobileStory: false});
  }

  render() {
    let landingHeader = this.state.size < 550 ? landingHeroMobile : landingHeroDesktop;

    let desertProps = {
      imgSrc: storyIsland,
      title: "Desert Island",
      textStyle: {textAlign: "center"},
      desc: "Will you choose love over friendship? Will the drama get in the way of your survival? How will you get off the island?",
      chapters: 15,

      mobileClick: this.storyCellMobile
    };
    let operationProps = {
      imgSrc: storyOperation,
      title: "Operation: Senior Year",
      textStyle: {textAlign: "center"},
      desc: "Being a secret agent is tough, do you have what it takes? Who can you trust and who will betray you? Can you solve the mystery and save the world?",
      chapters: 15,

      mobileClick: this.storyCellMobile
    };
    let pirateProps = {
      imgSrc: storyPirate,
      title: "Pirates of the Green Sea: Part One",
      textStyle: {textAlign: "center"},
      desc: "Set sail on the adventure of a lifetime. Will you find the cure and save your people, or will romance and infighting be your downfall? The fate of Maura lies in your hands.",
      chapters: 10,

      mobileClick: this.storyCellMobile
    };
    let cutProps = {
      imgSrc: storyCut,
      title: "Making the Cut",
      textStyle: {textAlign: "center"},
      desc: "When an exciting opportunity comes knocking, there’s only one option. Pack your bags and head to London in pursuit of your dreams. Can you rise above the lies and deceit to secure the job.",
      chapters: 5,

      mobileClick: this.storyCellMobile
    };
    let pirates02Props = {
      imgSrc: storyPirates02,
      title: "Pirates of the Green Sea: Part Two",
      textStyle: {textAlign: "center"},
      desc: "After a perilous journey, you’ve finally made it to Oroa. But can you secure the cure and make it home? The easy part is over, now the real adventure begins.",
      chapters: 9,

      mobileClick: this.storyCellMobile
    };
    let undergroundProps = {
      imgSrc: storyUnderground,
      title: "The Underground",
      textStyle: {textAlign: "center"},
      desc: "Aplistia is the last utopia in a desolate world. Saved only by a yearly sacrifice to keep the underground creatures away. And this year. Your name is drawn from the ballot.",
      chapters: 15,

      mobileClick: this.storyCellMobile
    };
    let trapAppProps = {
      imgSrc: storyTrapApp,
      title: "Trapped in an App",
      textStyle: {textAlign: "center"},
      desc: "Stuck in detention with a group of people who hate you? Only one thing you can do. Get stuck in an app instead.",
      chapters: 15,

      mobileClick: this.storyCellMobile
    };
    let buriedProps = {
      imgSrc: storyBuried,
      title: "Buried Dead or Alive",
      textStyle: {textAlign: "center"},
      desc: "What do you do when you wake up in a strange land, with a strangers’ face? Find out who you are and how you got there.",
      chapters: 15,

      mobileClick: this.storyCellMobile
    };
    let cabinFeverProps = {
      imgSrc: storyCabinFever,
      title: "Cabin Fever",
      textStyle: {textAlign: "center"},
      desc: "There's nothing worse than spending your weekend doing school work with former friends. Except maybe, being trapped in a desolate cabin with them.",
      chapters: 0,

      mobileClick: this.storyCellMobile
    };

    return (
      <Layout>
        <div
            className={styles.storyMobileContainer}
            style={{display: this.state.showMobileStory ? "flex" : "none"}}
            onClick={this.storyCellMobileHide}
        >
          <div className={styles.storyMobileBG}>
            <div className={styles.detailsBG}>
              <div className={styles.detailsWrap}>
                <div className={styles.detailsTitle}>{this.state.storyDetails.title}</div>
                <div className={styles.detailsDesc}>{this.state.storyDetails.desc}</div>
                <div className={styles.detailsChapters}>{this.state.storyDetails.chapters !== 0 ? this.state.storyDetails.chapters + " chapters" : "Coming soon"}</div>
              </div>
             </div>
          </div>
        </div>
        <img src={landingHeader} alt="Paths Header" className={styles.landingImage} />
        <div className={styles.aboutContainer}>
          <div className={styles.aboutContent} id="aboutPaths" >
            <SEO title="Paths" />
            <CustomTitle
                style={{width: `37vw`, margin: `1vw 0 2vw`}}
                titleStyle={{fontSize: "4.5vw"}}
                title={"What is Paths?"}
                barColor={"#F3A953"}
            />
            <div className={styles.whatIsDesc}>
              <p>
                Paths is an interactive storytelling game that allows you to choose your own adventure and influence the story through your choices.
              </p>
              <p>
                Explore original and exciting tales. Navigate love, mystery and adventure. Meet new characters and visit new places. The choice is yours, what path will you take?
              </p>
              <p>
                Available on iOS and Android devices today!
              </p>
            </div>
            <div className={styles.appStoreDown}>
                <a href="https://apps.apple.com/us/app/paths-make-your-choice/id1467842360?ls=1" style={{paddingRight: '10px'}}>
                    <img src={appStoreImage}/>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.cnztech.paths">
                    <img src={playStoreImage}/>
                </a>
                
            </div>
          </div>
        </div>


        <div className={styles.ourStoriesContainer}>
          <CustomTitle
            style={{width: `30vw`, margin: `3vw 0 2vw`}}
            titleStyle={{fontSize: "4.5vw"}}
            title={"Our Stories"}
            barColor={"#FFF"}
          />
          <div className={styles.storyCellContainer}>
            <StoryCell {...desertProps} />
            <StoryCell {...operationProps} />
            <StoryCell {...pirateProps} />
            <StoryCell {...cutProps} />
            <StoryCell {...pirates02Props} />
            <StoryCell {...undergroundProps} />
            <StoryCell {...trapAppProps} />
            <StoryCell {...buriedProps} />
            <StoryCell {...cabinFeverProps} />
          </div>
        </div>

        <div className={styles.faqContainer} id="faq">
          <CustomTitle
            style={{width: `12vw`, margin: `1vw 0 2vw`}}
            titleStyle={{fontSize: "4.5vw"}}
            title={"FAQ"}
            barColor={"#F3A953"}
            />

          <div style={{width: "50vw", maxWidth: "50vw"}}>
            {faq.map((questions) =>
              <div className={styles.faqContent}>
                <div className={styles.questionContent} style={{width: "100%"}}>
                  <div className={styles.questionTitle} style={{width: "100%"}}>{questions.question}</div>
                  {(this.state.visibleAnswers.includes(questions.index)) ?
                      <img src={minusFAQ} className={styles.questionHeader} onClick={() => (this.answerVisibility(questions.index))} alt="close FAQ" />
                        :
                      <img src={plusFAQ} className={styles.questionHeader} onClick={() => (this.answerVisibility(questions.index))} alt="open FAQ" />
                  }
                </div>

                {(this.state.visibleAnswers.includes(questions.index)) ?
                  <div className={styles.answerContent} dangerouslySetInnerHTML={{__html: questions.answer}}/>
                  : null
                }

              </div>
            )}
          </div>
        </div>

        <div className={styles.contactContainer} id="contact">
          <div className={styles.contactTitle}>
            <h1 >{"Have a good story idea you want to see come to life?"}</h1>
            <div style={{backgroundColor: "#F3A953"}}/>
          </div>

          <div className={styles.contactForm}>
            {this.state.submissionClicked ? (!this.state.name ? <div className={styles.warning}>Name</div> : null) : null}
            <input type='text' value={this.state.name} onChange={this.handleChangeName} placeholder='Name'/><br/>

            {this.state.submissionClicked ? (!this.state.email ? <div className={styles.warning}>Email</div> : null) : null}
            <input type='email' value={this.state.email} onChange={this.handleChangeEmail} placeholder='Email'/><br/>

            {this.state.submissionClicked ? (!this.state.subject ? <div className={styles.warning}>Subject</div> : null) : null}
            <input type='text' value={this.state.subject} onChange={this.handleChangeSubject} placeholder='Subject'/><br/>

            {this.state.submissionClicked ? (!this.state.message ? <div className={styles.warning}>Message</div> : null) : null}
            <textarea value={this.state.message} onChange={this.handleChangeMessage} placeholder='Enter your Message...' /> <br/>

            {this.state.response === 200 && this.state.submissionClicked ?
              <div>Thank you for contacting us, we will respond within 5 working days.</div>
            : <div className={styles.sendButton} onClick={this.sendMessage}>Send Message</div> }
          </div>

        </div>

      </Layout>
    )
  }
}

export default IndexPage

