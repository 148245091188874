import React from "react"
import styles from "./storycell.module.css"



class StoryCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 200,
      showDetails: false
    };

    this.showDetails = this.showDetails.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.setState({
        size: window.innerWidth
    });
    window.addEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({size: window.innerWidth});
  }

  showDetails() {
    if (this.state.size <= 1024) {
      this.props.mobileClick({
        'title': this.props.title,
        'desc': this.props.desc,
        'chapters': this.props.chapters
      });
    } else {
      this.setState({showDetails: !this.state.showDetails})
    }
  }



  render() {
    return (
      <div onClick={this.showDetails} className={styles.cellContainer}>
        <div style={{backgroundImage: "url(" + this.props.imgSrc + ")"}} className={styles.bookTileImage}>
          <div className={styles.detailsContainer} style={{opacity: this.state.showDetails ? "1" : "0"}}>
            <div className={styles.detailsWrap}>
              <div className={styles.detailsTitle}>{this.props.title}</div>
              <div className={styles.detailsDesc}>{this.props.desc}</div>
              <div className={styles.detailsChapters}>{this.props.chapters !== 0 ? this.props.chapters + " chapters" : "Coming soon"}</div>
            </div>
           </div>
        </div>

        {/*<div style={{backgroundImage: "url(" + this.props.imgSrc + ")"}} className={styles.storyContainer}>*/}
        {/*  <div className={styles.detailsContainer} style={{opacity: this.state.showDetails ? "1" : "0"}}>*/}
        {/*    <div className={styles.detailsWrap}>*/}
        {/*      <div className={styles.detailsTitle}>{this.props.title}</div>*/}
        {/*      <div className={styles.detailsDesc}>{this.props.desc}</div>*/}
        {/*      <div className={styles.detailsChapters}>{this.props.chapters !== 0 ? this.props.chapters + " chapters" : "Coming soon"}</div>*/}
        {/*    </div>*/}
        {/*   </div>*/}
        {/*</div>*/}
        <div className={styles.textStyle} style={this.props.textStyle}>{this.props.title}</div>
      </div>
    )
  }
}
export default StoryCell